/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useSite } from "hooks/site";
import { css } from "@emotion/react";
import ExportButton from "./ExportButton";
import downloadSpreadsheet from "libs/downloadSpreadsheet";
import { useState } from "react";
/**
 * Displays the site build mode status and the warehouses, and allows the user
 * to toggle build mode before viewing the blueprint.
 */
export default function ExportModal({ SiteId }) {
  const { site } = useSite(SiteId);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [exporting, setExporting] = useState();
  const handleStartDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setToDate(event.target.value);
  };

  if (!site) return null;
  // Define Emotion styles
  const containerStyles = css`
    padding: 0 16px 16px 16px;
  `;

  const dateInputContainerStyles = css`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
  `;

  const dateInputStyles = css`
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 8px;
    &:focus {
      outline: none;
      border-color: #2d72ed;
      box-shadow: 0 0 0 2px rgba(45, 114, 237, 0.5);
    }
  `;

  const labelStyles = css`
    margin-bottom: 4px;
  `;

  const footerStyles = css`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  `;

  const dateContainerStyles = css`
    display: flex;
    flex-direction: column;
  `;

  return (
    <div css={containerStyles}>
      <div css={dateInputContainerStyles}>
        <div css={dateContainerStyles}>
          <label css={labelStyles}>From</label>
          <input
            type="date"
            css={dateInputStyles}
            onChange={handleStartDateChange}
          />
        </div>
        <div css={dateContainerStyles}>
          <label css={labelStyles}>To</label>
          <input
            type="date"
            css={dateInputStyles}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <div css={footerStyles}>
        <ExportButton
          onClickExport={async () => {
            setExporting(true);
            await downloadSpreadsheet(
              SiteId,
              site?.client?.name,
              site?.name,
              fromDate,
              toDate,
            );
            setExporting(false);
          }}
          label={exporting ? "Exporting..." : "Export"}
          disabled={!fromDate && !toDate}
        />
      </div>
    </div>
  );
}

ExportModal.propTypes = {
  SiteId: PropTypes.string.isRequired,
};
