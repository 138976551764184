/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getSites(filters) {
  const res = await apiCall(`/site${filterUrlGenerator(filters)}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error("Problem getting sites");
}

export async function toggleSiteBuildMode(SiteId) {
  const res = await apiCall("/site/buildmode", {
    method: "PUT",
    body: JSON.stringify({ SiteId }),
  });
  return res;
}

export async function getSite(SiteId) {
  const res = await apiCall(`/site/${SiteId}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting site");
}

export async function deleteSite(SiteId) {
  const res = await apiCall(`/site/${SiteId}`, {
    method: "DELETE",
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error("Problem deleting site");
}

export async function editSite(site) {
  const res = await apiCall("/site", {
    method: "PUT",
    body: JSON.stringify(site),
  });
  if (res.ok) {
    return res.json();
  }
  throw new Error("Problem editing site");
}

export async function addSite(site) {
  const res = await apiCall("/site", {
    method: "POST",
    body: JSON.stringify(site),
  });
  if (res.ok) {
    return res.json();
  }

  throw new Error("Problem adding site");
}

export async function getLocationReport(SiteId, filters) {
  return apiCall(`/site/report/${SiteId}${filterUrlGenerator(filters)}`);
}

export async function getLocationSpreadsheet(filters) {
  return apiCall(
    `/site/report/offloaded/spreadsheet/${filterUrlGenerator(filters)}`,
  );
}

export async function getDamageSpreadsheet(filters) {
  return apiCall(
    `/site/report/damage/spreadsheet/${filterUrlGenerator(filters)}`,
  );
}

export async function getOffloadList(filters) {
  const res = await apiCall(`/site/offload/list${filterUrlGenerator(filters)}`);
  if (res.ok) {
    return res.json();
  }
}
