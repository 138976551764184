import PropTypes from "prop-types";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

import { useFilters } from "hooks/useFilter";

import FilterOptionsAndChips from "views/components/FilterOptionsAndChips";

import { ControlsWrapper } from "./styles";
import "views/styles/Home.css";
import ExportButton from "views/pages/Locations/components/ExportButton";
import VerticalDivider from "views/components/Tables/VerticalDivider";

export default function Controls({ onClickExport }) {
  const { clientId, siteId } = useFilters();
  const isTescoDemo = clientId === "d7e7c584-a6b8-48c3-89dd-ec1003baa578";
  return (
    <ControlsWrapper>
      <FilterOptionsAndChips hideFilterOptions={siteId} />
      <div css={{ justifyContent: "flex-end" }}>
        {isTescoDemo && (
          <>
            <ExportButton
              onClickExport={onClickExport}
              label="Weekly Report"
              width="140px"
            />
            <VerticalDivider />{" "}
          </>
        )}
        <Button disabled={!siteId} href={`/blueprint/${siteId}`}>
          <FontAwesomeIcon icon={faPencilRuler} style={{ marginRight: 10 }} />
          Blueprint
        </Button>
      </div>
    </ControlsWrapper>
  );
}

Controls.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClickExport: PropTypes.object,
};
