import { PRIMARY_DATETIME_FORMAT } from "libs/constants";
import moment from "moment";
import PropTypes from "prop-types";
import DamageCircle from "views/components/DamageCircle";

export default function LocationRow({ location, showAll }) {
  return (
    <>
      <tr>
        <td>{<DamageCircle RiskName={location.risk} />}</td>
        <td>
          {location.RackName} {location.PalletName}
          {location.LocationName}
        </td>
        <td>{location.customerReference}</td>
        <td>
          {location.loadedAt || !location.offloadedAt
            ? "Reopened"
            : "Currently Offloaded"}
        </td>
        <td>{location.component}</td>
        <td>{moment(location.offloadedAt).format(PRIMARY_DATETIME_FORMAT)}</td>
        {showAll && (
          <td>
            {location.loadedAt
              ? moment(location.loadedAt).format(PRIMARY_DATETIME_FORMAT)
              : "-"}
          </td>
        )}
      </tr>
    </>
  );
}

LocationRow.defaultProps = {};

LocationRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  showAll: PropTypes.bool,
};
