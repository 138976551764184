import PropTypes from "prop-types";
import LocationRow from "./LocationRow";

export default function LocationRows({
  locations = [],
  client,
  site,
  showAll,
}) {
  return (
    <>
      {locations.map((location) => (
        <LocationRow
          key={location.id}
          location={location}
          showClient={!client}
          showSite={!site}
          showAll={showAll}
        />
      ))}
    </>
  );
}

LocationRows.defaultProps = {};

LocationRows.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  locations: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object,
  showAll: PropTypes.bool,
};
