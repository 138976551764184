import { getLocationSpreadsheet } from "./api/site";

export default async function downloadSpreadsheet(
  SiteId,
  ClientName,
  SiteName,
  fromDate,
  toDate,
) {
  const res = await getLocationSpreadsheet({ SiteId, fromDate, toDate });

  const blob = await res.blob();
  const fileURL = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = fileURL;
  tempLink.setAttribute("download", `${ClientName} - ${SiteName}.xlsx`);
  tempLink.click();
}
