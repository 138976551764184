/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

export default function ExportButton({
  onClickExport,
  label,
  width,
  disabled,
}) {
  return (
    <Button
      css={css`
        height: 40px;
        width: ${width || "180px"};
        border-radius: 50px;
        background-color: #2d72ed;
        &:hover,
        &:focus {
          background-color: #2d72ed;
        }
      `}
      onClick={onClickExport}
      disabled={disabled}
    >
      <FontAwesomeIcon
        icon={faFileExcel}
        size="1xl"
        style={{ marginRight: 10 }}
      />
      {label}
    </Button>
  );
}

ExportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClickExport: PropTypes.func,
  label: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};
