/** @jsxImportSource @emotion/react */
import { faCheck, faList, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useClient } from "hooks/client";
import { useSite } from "hooks/site";
import { useDownloader } from "hooks/useDownloader";
import { FiltersProvider, FILTER_KEYS, useFilters } from "hooks/useFilter";

import PageWrapper from "views/components/common/PageWrapper";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import PageButtons from "views/components/Tables/PageButtons";
import { Table } from "views/components/Tables/styles";
import {
  MiniFilterButton,
  MiniFilterTab,
  MiniFilterWrapper,
  TableControlsWrapper,
} from "views/components/Tables/TableControls";
import TableHeader from "views/components/Tables/TableHeader";

import Controls from "./components/Controls";
import { useOffloadList } from "hooks/locations";
import LocationRows from "./components/LocationRows";
import downloadDamageSpreadsheet from "libs/downloadDamageSpreadsheet";

const PAGE_SIZE = 10;

function LocationsPage() {
  const [page, setPage] = useState(0);

  const [allLocations, setAllLocations] = useState(true);
  const [offloaded, setOffloaded] = useState();

  const { siteId, clientId, searchText, dateRangeFrom, dateRangeTo } =
    useFilters();

  const { client, isLoading: isClientLoading } = useClient(clientId);
  const { site, isLoading: isSiteLoading } = useSite(siteId);

  const { onStart, onEnd } = useDownloader();

  const searchFilters = useMemo(
    () => ({
      SiteId: siteId,
      page,
      searchText,
      amount: PAGE_SIZE,
      offloaded,
      fromDate: dateRangeFrom,
      toDate: dateRangeTo,
    }),
    [clientId, siteId, searchText, page, offloaded, dateRangeFrom, dateRangeTo],
  );

  /**
   * Data Loading Hooks
   */
  const {
    locations,
    isLoading: isActionsLoading,
    total: totalLocations,
    mutate: refreshActions,
    isValidating,
  } = useOffloadList(searchFilters);
  const onExportDamage = useCallback(async () => {
    onStart(siteId);
    const toastId = toast(`Exporting report`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      closeButton: false,
      type: toast.TYPE.INFO,
      autoClose: false,
      pauseOnHover: false,
    });
    await downloadDamageSpreadsheet(
      siteId,
      client?.name,
      site?.name,
      searchText,
      dateRangeFrom,
      dateRangeTo,
    );
    toast.update(toastId, {
      render: `Exported report`,
      type: toast.TYPE.SUCCESS,
      autoClose: 4000,
    });
    onEnd(siteId);
  }, [onEnd, onStart, searchText, dateRangeFrom, dateRangeTo, client, site]);

  const totalPages = Math.ceil(totalLocations / PAGE_SIZE);

  const isLoading = isActionsLoading || isClientLoading || isSiteLoading;
  const tagLine = allLocations
    ? "List of damage locations"
    : "List of currently offloaded locations";
  return (
    <PageWrapper>
      <Helmet>
        <title>
          {`Econform / ${client ? `${client.name} / ` : ""} ${
            site ? `${site.name} / ` : ""
          } Locations`}
        </title>
      </Helmet>
      <Controls onClickExportDamage={onExportDamage} />
      <TableHeader
        largeLeftTagline={tagLine}
        largeLeftTitle="Locations"
        right={`Showing ${locations && locations.length} of ${
          totalLocations || 0
        } Locations`}
      />
      <TableControlsWrapper>
        <MiniFilterWrapper>
          <div>
            <MiniFilterTab
              onClick={() => {
                setOffloaded(false);
                setAllLocations(true);
                setPage(0);
              }}
              selected={allLocations}
            >
              {allLocations ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faList} />
              )}
              Damage Locations
            </MiniFilterTab>
            <MiniFilterTab
              onClick={() => {
                setOffloaded(true);
                setAllLocations(false);
                setPage(0);
              }}
              selected={offloaded}
            >
              {offloaded ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faList} />
              )}{" "}
              Currently Offloaded
            </MiniFilterTab>
          </div>
          <MiniFilterButton onClick={() => refreshActions()}>
            {isLoading || isValidating ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : null}
            Refresh
          </MiniFilterButton>
        </MiniFilterWrapper>
      </TableControlsWrapper>
      <Table>
        <thead>
          <tr>
            <th>Risk</th>
            <th>Location Name</th>
            <th>Tag</th>
            <th>Status</th>
            <th>Component</th>
            <th>Offloaded Date</th>
            {!offloaded && <th>Reopened Date</th>}
          </tr>
        </thead>
        <tbody>
          {!isLoading && (
            <LocationRows
              locations={locations}
              client={client}
              site={site}
              showAll={allLocations}
            />
          )}
        </tbody>
      </Table>
      {(locations && locations.length === 0) || isLoading ? (
        <EmptyTableIndicator
          isLoading={isLoading}
          searchable
          type="Actions"
          typeLabel="Locations"
        />
      ) : null}
      <PageButtons
        curPage={page}
        onChangePage={setPage}
        totalPages={totalPages}
      />
    </PageWrapper>
  );
}

/**
 * An locations page can be the global one, as part of a client, or as part of a
 * site. This wrapping component provides the filter context for the page.
 */
function LocationsPageInURLContext() {
  const { ClientId: urlClientId, SiteId: urlSiteId } = useParams();

  const controlledValues = {
    [FILTER_KEYS.clientId]: urlClientId,
    [FILTER_KEYS.siteId]: urlSiteId,
  };

  const enabledFilters = [
    FILTER_KEYS.dateRangeTo,
    FILTER_KEYS.dateRangeFrom,
  ].filter(Boolean);

  return (
    <FiltersProvider
      controlledValues={controlledValues}
      enabledFilters={enabledFilters}
      key={`${urlClientId}_${urlSiteId}`}
    >
      <LocationsPage />
    </FiltersProvider>
  );
}

export default LocationsPageInURLContext;
