import useSWR from "swr";

import { getOffloadList } from "libs/api/site";

export function useOffloadList(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/site/offload/list`, filters],
    async () => getOffloadList(filters),
  );
  return {
    locations: data?.results ?? [],
    isLoading,
    isError: error,
    total: data?.total ?? 0,
    mutate,
    isValidating,
  };
}
