/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, useLocation } from "react-router-dom"; // compat

import { useCurrentUser } from "hooks/user";

import ErrorFallback from "views/components/ErrorFallback";
import Header from "views/components/Header";
import Loading from "views/components/Loading";
import ChangePasswordModal from "views/components/Modals/ChangePasswordModal";
import SideBar from "views/components/Sidebar";
import Action from "views/pages/Action";
import Actions from "views/pages/Actions";
import PageBlueprintActions from "views/pages/blueprint-actions";
import PageInspectionBlueprint from "views/pages/blueprint-inspection";
import PageBlueprint from "views/pages/blueprint-site";
import Client from "views/pages/Client";
import Clients from "views/pages/Clients";
import PageAddClient from "views/pages/ClientsEditor/page-add-client";
import PageEditClient from "views/pages/ClientsEditor/page-edit-client";
import Dashboard from "views/pages/Dashboard";
import Inactive from "views/pages/Inactive";
import Inspection from "views/pages/Inspection/index";
import InspectionEditor from "views/pages/InspectionEditor";
import Inspections from "views/pages/Inspections";
import Login from "views/pages/Login";
import Schedules from "views/pages/Schedules";
import Settings from "views/pages/Settings";
import Site from "views/pages/Site";
import Sites from "views/pages/Sites";
import PageAddSite from "views/pages/SitesEditor/page-add-site";
import PageEditSite from "views/pages/SitesEditor/page-edit-site";
import UserEditor from "views/pages/UserEditor";
import Users from "views/pages/Users";
import Warehouses from "views/pages/Warehouses";
import WarehousesEditor from "views/pages/WarehousesEditor";
import Locations from "views/pages/Locations";

const FullScreen = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex: 1;
`;

const MainSection = styled.main`
  overflow-y: scroll;
  margin-top: 50px;
  width: calc(100vw ${(props) => (props.hideSidebar ? "" : "- 225px")});
`;

function Main() {
  const location = useLocation();
  const { user, isLoading } = useCurrentUser();

  const authState = user ? "loggedIn" : "loggedOut";
  const hideSidebarLocations = [
    "/login",
    "/inactive",
    "/blueprint",
    "/inspections/blueprint",
    "/actions/blueprint",
  ];
  const hideHeaderLocations = ["login", "inactive"];

  const curLocation = location.pathname.split("/")[1];
  const hideSidebar = hideSidebarLocations.some((loc) =>
    location.pathname.startsWith(loc),
  );

  const hideHeader =
    hideHeaderLocations.includes(curLocation) || authState === "loggedOut";
  const isInstallerUser = user?.installerOrganisationClient.length;
  /**
   * If the user is loading, show the loading spinner
   */
  if (isLoading) {
    return (
      <FullScreen>
        <Loading height="100vh" />
      </FullScreen>
    );
  }

  /**
   * If the user is logged out, show the login page
   */
  if (!user) {
    return (
      <FullScreen>
        <Login />
      </FullScreen>
    );
  }

  if (user.active === false) {
    return (
      <FullScreen>
        <Inactive />
      </FullScreen>
    );
  }

  return (
    <FullScreen>
      {!hideHeader && <Header hideHeader={hideHeader} />}
      {!hideSidebar && <SideBar />}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <MainSection hideSidebar={hideSidebar}>
          <Routes>
            <Route element={<Inactive />} exact path="/inactive" />
            {isInstallerUser ? (
              <Route element={<Actions />} exact path="/" />
            ) : (
              <Route element={<Dashboard />} exact path="/" />
            )}

            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<Clients />} exact path="/clients" />
            <Route element={<PageAddClient />} path="/clients/add" />
            <Route element={<Client />} path="/clients/:ClientId/overview" />
            <Route
              element={<Schedules />}
              path="/clients/:ClientId/schedules/:TabIndex?"
            />
            <Route element={<Sites />} exact path="/clients/:ClientId/sites" />
            <Route
              element={<PageAddSite />}
              exact
              path="/clients/:ClientId/sites/add"
            />
            <Route
              element={<PageEditSite />}
              path="/clients/:ClientId/sites/:SiteId/edit"
            />
            <Route
              element={<UserEditor />}
              path="/clients/:ClientId/users/add"
            />
            <Route
              element={<Actions />}
              path="/clients/:ClientId/actions/:TabIndex?"
            />
            <Route element={<Users />} exact path="/clients/:ClientId/users" />
            <Route
              element={<Inspections />}
              path="/clients/:ClientId/inspections/:TabIndex?"
            />
            <Route
              element={<PageEditClient />}
              path="/clients/:ClientId/edit"
            />

            <Route
              // TODO: Which of these site editor routes are actually
              element={<PageEditSite />}
              exact
              path="/clients/sites/:SiteId/edit"
            />
            <Route
              element={<Schedules />}
              path="/clients/sites/:SiteId/schedules/:TabIndex?"
            />
            <Route element={<Site />} path="/clients/sites/:SiteId/overview" />
            <Route
              element={<Actions />}
              path="/clients/sites/:SiteId/actions/:TabIndex?"
            />
            <Route
              element={<Locations />}
              path="/clients/sites/:SiteId/locations"
            />
            <Route
              element={<Users />}
              exact
              path="/clients/sites/:SiteId/users"
            />
            <Route
              element={<UserEditor />}
              path="/clients/sites/:SiteId/users/add"
            />
            <Route
              element={<Inspections />}
              path="/clients/sites/:SiteId/inspections/:TabIndex?"
            />
            <Route
              element={<Warehouses />}
              exact
              path="/clients/sites/:SiteId/warehouses"
            />
            <Route
              element={<WarehousesEditor />}
              exact
              path="/clients/sites/:SiteId/warehouses/add/:Warehouse?"
            />

            <Route
              element={<WarehousesEditor />}
              path="/warehouses/:WarehouseId/edit/"
            />

            <Route
              element={<PageBlueprint />}
              path="/blueprint/:SiteId/:WarehouseId?/:InspectionId?/:type?"
            />

            <Route
              element={<Inspections />}
              exact
              path="/inspections/:TabIndex?"
            />
            <Route
              element={<InspectionEditor />}
              exact
              path="/inspection/edit/:InspectionId"
            />
            <Route
              element={<Inspection />}
              path="/inspections/view/:InspectionId/:WarehouseId?/:RackId?"
            />
            <Route
              element={<PageInspectionBlueprint />}
              path="/inspections/blueprint/:InspectionId/:WarehouseId?"
            />

            <Route element={<Schedules />} path="/schedules/:TabIndex?" />

            <Route element={<Actions />} exact path="/actions/:TabIndex?" />
            <Route element={<Action />} path="/actions/view/:ActionId" />
            <Route
              element={<PageBlueprintActions />}
              path="/actions/blueprint/:InspectionId/:WarehouseId?/"
            />

            <Route element={<Users />} exact path="/users" />
            <Route element={<UserEditor />} exact path="/users/:UserId/edit" />
            <Route
              element={<UserEditor />}
              exact
              path="/users/:UserId/edit/organisation/:OrganisationId"
            />
            <Route
              element={<UserEditor />}
              exact
              path="/users/:UserId/edit/client/:ClientId"
            />
            <Route
              element={<UserEditor />}
              exact
              path="/users/add/client/:ClientId"
            />
            <Route element={<UserEditor />} exact path="/users/add" />
            <Route
              element={<UserEditor />}
              path="/organisation/:OrganisationId/users/add"
            />

            <Route element={<Settings />} exact path="/settings/verify?" />
          </Routes>
        </MainSection>
      </ErrorBoundary>

      {user && user.resetPasswordOnLogin && <ChangePasswordModal user={user} />}
    </FullScreen>
  );
}

export default Main;
