import PropTypes from "prop-types";

import FilterOptionsAndChips from "views/components/FilterOptionsAndChips";

import { ControlsButtonWrapper, ControlsWrapper } from "../styles";
import ExportButton from "./ExportButton";

export default function Controls({ onClickExportDamage }) {
  return (
    <ControlsWrapper>
      <FilterOptionsAndChips showSearch />
      <ControlsButtonWrapper>
        <ExportButton
          onClickExport={onClickExportDamage}
          label="Damage Location Report"
          width="230px"
        />
      </ControlsButtonWrapper>
    </ControlsWrapper>
  );
}

Controls.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClickExportDamage: PropTypes.func,
};
