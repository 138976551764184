import { getDamageSpreadsheet } from "./api/site";

export default async function downloadDamageSpreadsheet(
  SiteId,
  ClientName,
  SiteName,
  searchText,
  fromDate,
  toDate,
) {
  const res = await getDamageSpreadsheet({
    SiteId,
    searchText,
    fromDate,
    toDate,
  });

  const blob = await res.blob();
  const fileURL = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = fileURL;
  tempLink.setAttribute("download", `${ClientName} - ${SiteName}.xlsx`);
  tempLink.click();
}
