// import { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import moment from "moment";
import PropTypes from "prop-types";

import { useSites } from "hooks/site";
import { FILTER_KEYS, useFilters } from "hooks/useFilter";

import ClientChip from "views/components/FilterChip/chip-client";
import SiteChip from "views/components/FilterChip/chip-site";

import FilterChip from "../FilterChip";
import SearchBar from "../SearchBar";

import FilterOptions from "./FilterOptions";

const amount = 100;

const ChipWrapper = styled.div`
  // margin-left: ${(props) => (props.hideFilterOptions ? "0" : "105px")};
  white-space: nowrap;
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #494c55;
  }
  ::-webkit-scrollbar-thumb {
    background: #2d72ed;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const FilterButtonWrapper = styled.div`
  margin: 0 5px;
`;

const Wrapper = styled.div`
  display: flex;
`;

function FilterOptionsAndChips({
  hideFilterOptions,
  organisation,
  showSearch,
}) {
  const {
    clients,

    clientId,
    siteId,
    completedAtEndDate,
    completedAtStartDate,
    createdAtEndDate,
    createdAtStartDate,
    dateRangeFrom,
    dateRangeTo,
    actionStatus,
    inspectionStatus,

    setClientId,
    setSiteId,
    setCompletedAtEndDate,
    setCompletedAtStartDate,
    setCreatedAtEndDate,
    setCreatedAtStartDate,
    setDateRangeFrom,
    setDateRangeTo,
    setInspectionStatus,
    setActionStatus,

    canUpdate,
  } = useFilters();

  const filters = { ClientId: clientId, amount };

  // TODO: Move total to filter hook
  const { total: totalSites } = useSites(filters);

  return (
    <Wrapper>
      {showSearch && <SearchBar />}
      {!hideFilterOptions ? (
        <FilterButtonWrapper hasSearch={showSearch}>
          <FilterOptions />
        </FilterButtonWrapper>
      ) : null}
      <ChipWrapper hideFilterOptions={hideFilterOptions}>
        {organisation && (
          <FilterChip text={organisation.name} type="organisation" />
        )}
        {clientId && (
          <ClientChip
            ClientId={clientId}
            hideRemove={
              clients.length === 1 || !canUpdate(FILTER_KEYS.clientId)
            }
            onRemove={() => {
              setClientId(null);
              setSiteId(null);
            }}
            type="clients"
          />
        )}
        {siteId ? (
          <SiteChip
            SiteId={siteId}
            hideRemove={totalSites === 1 || !canUpdate(FILTER_KEYS.siteId)}
            onRemove={() => setSiteId(null)}
            type="sites"
          />
        ) : null}
        {actionStatus && (
          <FilterChip
            onRemove={() => setActionStatus(null)}
            text={actionStatus}
            type="action-status"
          />
        )}
        {inspectionStatus && (
          <FilterChip
            onRemove={() => setInspectionStatus(null)}
            text={inspectionStatus}
            type="inspection-status"
          />
        )}
        {createdAtStartDate && !createdAtEndDate && (
          <FilterChip
            onRemove={() => setCreatedAtStartDate(null)}
            text={`Conducted after ${moment(createdAtStartDate).format(
              "DD-MM-YY",
            )}`}
            type="date"
          />
        )}
        {!createdAtStartDate && createdAtEndDate && (
          <FilterChip
            onRemove={() => setCreatedAtEndDate(null)}
            text={`Conducted before at ${moment(createdAtEndDate).format(
              "DD-MM-YY",
            )}`}
            type="date"
          />
        )}
        {createdAtStartDate && createdAtEndDate && (
          <FilterChip
            onRemove={() => {
              setCreatedAtStartDate(null);
              setCreatedAtEndDate(null);
            }}
            text={`Conducted ${moment(createdAtStartDate).format(
              "DD-MM-YY",
            )} to ${moment(createdAtEndDate).format("DD-MM-YY")}`}
            type="date"
          />
        )}
        {completedAtStartDate && !completedAtEndDate && (
          <FilterChip
            onRemove={() => setCompletedAtStartDate(null)}
            text={`Completed after ${moment(completedAtStartDate).format(
              "DD-MM-YY",
            )}`}
            type="date"
          />
        )}
        {!completedAtStartDate && completedAtEndDate && (
          <FilterChip
            onRemove={() => setCompletedAtEndDate(null)}
            text={`Completed before ${moment(completedAtEndDate).format(
              "DD-MM-YY",
            )}`}
            type="date"
          />
        )}
        {completedAtStartDate && completedAtEndDate && (
          <FilterChip
            onRemove={() => {
              setCompletedAtStartDate(null);
              setCompletedAtEndDate(null);
            }}
            text={`Completed ${moment(completedAtStartDate).format(
              "DD-MM-YY",
            )} to ${moment(completedAtEndDate).format("DD-MM-YY")}`}
            type="date"
          />
        )}
        {dateRangeFrom && dateRangeTo && (
          <FilterChip
            onRemove={() => {
              setDateRangeFrom(null);
              setDateRangeTo(null);
            }}
            text={`From ${moment(dateRangeFrom).format("DD-MM-YY")} to ${moment(
              dateRangeTo,
            ).format("DD-MM-YY")}`}
            type="date"
          />
        )}
      </ChipWrapper>
    </Wrapper>
  );
}

FilterOptionsAndChips.propTypes = {
  hideFilterOptions: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  organisation: PropTypes.object,
  showSearch: PropTypes.bool,
};

export default FilterOptionsAndChips;
